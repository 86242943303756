import { Text, View } from '@react-pdf/renderer';
import PageTemplate from 'pdf/components/PageTemplate';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { Heading1, Heading2 } from 'pdf/components/typography';
import generatePdf from 'pdf/generatePdf';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import replaceAggregatorPlaceholders from 'helpers/replaceAggregatorPlaceholders';
import replaceQuotePlaceholders from 'helpers/replaceQuotePlaceholders';
import { AddonSelection } from 'state/addonsSelection/addonsSelection';
import {
  AggregatorName,
  isAggregatorQuote,
  isRenewal,
  Quote,
  Renewal,
} from 'state/quote/quote';
import CoversSection from './components/CoversSection';
import ExcessSection from './components/ExcessSection';
import PaymentSection from './components/PaymentSection';
import SelectedTierSection from './components/SelectedTierSection';
import TieredQuoteIncludedItems from './components/TieredQuoteIncludedItems';
import TieringTable from './components/TieringTable';
import { CsAggregatorsPdf, TieredQuoteSummaryPdfContent } from './types';

type TieredQuoteSummaryPdfProps = {
  quote: Quote | Renewal;
  addOnSelection: AddonSelection;
  content: TieredQuoteSummaryPdfContent & {
    tieredPolicyChangeMessage: string | undefined;
  };
  theme: DefaultTheme;
};

const getAggregatorName = (
  aggregatorName: AggregatorName | null,
  csAggregators: CsAggregatorsPdf
): string => {
  switch (aggregatorName) {
    case 'CTM':
      return csAggregators.ctm.display_name;
    case 'Confused':
      return csAggregators.confused.display_name;
    case 'GoCompare':
      return csAggregators.gocompare.display_name;
    case 'MSM':
      return csAggregators.msm.display_name;
    default:
      return '';
  }
};

const TieredQuoteSummaryPdf: React.FC<TieredQuoteSummaryPdfProps> = ({
  theme,
  quote,
  addOnSelection,
  content,
}) => {
  const {
    csQuoteSummary,
    csTieredQuoteSummary,
    csTieringTable,
    csTieredCheckDetails,
    csAnnualPaymentTable,
    csMonthlyPaymentTable,
    csPayment,
    csAggregators,
    csAddOnsConfig,
    csMyPolicyPage,
    allCsAddOn,
    csAddOnBreakdownCover,
    csPdfTemplate,
    tieredPolicyChangeMessage,
  } = replaceQuotePlaceholders(
    replaceAggregatorPlaceholders(content, quote, content.csAggregators),
    quote
  );

  return (
    <PageTemplate logoSrc={csPdfTemplate.logo.localAsset.publicURL}>
      <Section>
        {isAggregatorQuote(quote) && !isRenewal(quote) && (
          <>
            <Heading1>{`${
              csQuoteSummary.summary_section.aggregator_summary_text
            } ${getAggregatorName(quote.aggregatorName, csAggregators)}`}</Heading1>
            <SelectedTierSection
              theme={theme}
              quote={quote}
              csQuoteSummary={csQuoteSummary}
              csTieredQuoteSummary={csTieredQuoteSummary}
              csTieredCheckDetails={csTieredCheckDetails}
              csPayment={csPayment}
            />
          </>
        )}
        {isRenewal(quote) && (
          <>
            <Heading1>{csQuoteSummary.hero_renewal.heading}</Heading1>
            {!!csQuoteSummary.hero_renewal.subheading && (
              <Text>{csQuoteSummary.hero_renewal.subheading}</Text>
            )}
            {tieredPolicyChangeMessage && (
              <RichText theme={theme} html={tieredPolicyChangeMessage} />
            )}
            <SelectedTierSection
              theme={theme}
              quote={quote}
              csQuoteSummary={csQuoteSummary}
              csTieredQuoteSummary={csTieredQuoteSummary}
              csTieredCheckDetails={csTieredCheckDetails}
              csPayment={csPayment}
            />
          </>
        )}
      </Section>
      <View wrap={false}>
        {isRenewal(quote) ? (
          <>
            <Heading2>{csTieredQuoteSummary.tiered_renewal_header.heading}</Heading2>
            <Text>{csTieredQuoteSummary.tiered_renewal_header.subheading}</Text>
          </>
        ) : (
          <>
            {isAggregatorQuote(quote) ? (
              <Heading2>{csTieredQuoteSummary.tiered_header.selected_heading}</Heading2>
            ) : (
              <>
                <Heading1>
                  {csTieredQuoteSummary.tiered_header.unselected_heading}
                </Heading1>
                <Text>{csTieredQuoteSummary.tiered_header.unselected_subheading}</Text>
              </>
            )}
          </>
        )}
        <TieringTable
          theme={theme}
          quote={quote}
          csTieringTable={csTieringTable}
          csTieredCheckDetails={csTieredCheckDetails}
        />
        <TieredQuoteIncludedItems
          theme={theme}
          csTieredQuoteSummary={csTieredQuoteSummary}
        />
      </View>
      {!isRenewal(quote) && !isAggregatorQuote(quote) && (
        <SelectedTierSection
          theme={theme}
          quote={quote}
          csQuoteSummary={csQuoteSummary}
          csTieredQuoteSummary={csTieredQuoteSummary}
          csTieredCheckDetails={csTieredCheckDetails}
          csPayment={csPayment}
        />
      )}
      <CoversSection
        theme={theme}
        quote={quote}
        addOnSelection={addOnSelection}
        csQuoteSummary={csQuoteSummary}
        csAddOns={allCsAddOn.nodes}
        csAddOnsConfig={csAddOnsConfig}
        csAddOnBreakdownCover={csAddOnBreakdownCover}
        csAddOnIncludedInExtra={csTieredQuoteSummary.add_on_included_in_extra}
      />
      <ExcessSection theme={theme} quote={quote} csQuoteSummary={csQuoteSummary} />
      <PaymentSection
        theme={theme}
        quote={quote}
        csQuoteSummary={csQuoteSummary}
        csAnnualPaymentTable={csAnnualPaymentTable}
        csMonthlyPaymentTable={csMonthlyPaymentTable}
      />
      {isRenewal(quote) && (
        <RichText
          theme={theme}
          style={{
            marginTop: '16',
          }}
          html={csMyPolicyPage.cancel_renewal_text}
        />
      )}
    </PageTemplate>
  );
};

export default TieredQuoteSummaryPdf;

export const generateTieredQuoteSummaryPdf = async (
  props: TieredQuoteSummaryPdfProps
): Promise<void> => generatePdf(<TieredQuoteSummaryPdf {...props} />, 'Quote');
