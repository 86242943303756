import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { CsTieredQuoteSummaryPdf } from 'pdf/TieredQuoteSummaryPdf/types';
import BulletItem from 'pdf/components/BulletItem';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { Heading3 } from 'pdf/components/typography';
import React from 'react';

type TieredQuoteIncludedItemsProps = {
  theme: Theme;
  csTieredQuoteSummary: CsTieredQuoteSummaryPdf;
};

const TieredQuoteIncludedItems: React.FC<TieredQuoteIncludedItemsProps> = ({
  theme,
  csTieredQuoteSummary,
}) => {
  return (
    <Section>
      <Heading3>{csTieredQuoteSummary.summary_section.quote_includes.heading}</Heading3>
      {csTieredQuoteSummary.summary_section.quote_includes.included_item.map((item) => (
        <BulletItem key={item.included_item_text} theme={theme}>
          <RichText theme={theme} html={item.included_item_text} />
        </BulletItem>
      ))}
    </Section>
  );
};

export default TieredQuoteIncludedItems;
