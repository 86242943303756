import { Text } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { isAnnualPayment } from 'api/businessLogic/quote';
import {
  CsTieredCheckDetailsPdf,
  CsTieringTablePdf,
} from 'pdf/TieredQuoteSummaryPdf/types';
import Section from 'pdf/components/Section';
import { Table, TableCell, TableRow } from 'pdf/components/Table';
import React from 'react';
import { ProductItemStatus } from 'components/TierChooserTable/TieredProductItem';
import {
  formatInPoundsAndPenceWithSign,
  formatInPoundsWithSign,
} from 'helpers/poundsHelpers';
import {
  replaceBreakdownPlaceholders,
  replaceLegalPlaceholders,
} from 'helpers/replaceTierTablePlaceholders';
import {
  getCurrentTier,
  LegalCoverageType,
  Quote,
  Renewal,
  TierOptions,
} from 'state/quote/quote';

type TieringTableProps = {
  theme: Theme;
  quote: Quote | Renewal;
  csTieringTable: CsTieringTablePdf;
  csTieredCheckDetails: CsTieredCheckDetailsPdf;
};

const firstColumnWidth = '28%';
const otherColumnWidth = '24%';

type ProductStatusCellProps = {
  theme: Theme;
  status: ProductItemStatus;
  text: string;
};

const ProductStatusCell: React.FC<ProductStatusCellProps> = ({ theme, status, text }) => {
  const getColor = (): string => {
    switch (status) {
      case ProductItemStatus.SELECTED:
      case ProductItemStatus.INCLUSIVE:
        return theme.colors.core01;
      case ProductItemStatus.UNAVAILABLE:
        return theme.colors.notificationError;
      case ProductItemStatus.UNSELECTED:
      default:
        return theme.colors.neutral01;
    }
  };

  return (
    <TableCell theme={theme} width={otherColumnWidth}>
      <Text
        style={{
          color: getColor(),
          textTransform: 'capitalize',
        }}>
        {text}
      </Text>
    </TableCell>
  );
};

const TieringTable: React.FC<TieringTableProps> = ({
  theme,
  quote,
  csTieringTable,
  csTieredCheckDetails,
}) => {
  const tierPrices = quote.tieringInfo?.tiers.map((tier) =>
    isAnnualPayment(quote)
      ? tier.paymentDetails.annualPayment.total ?? 0
      : tier.paymentDetails.monthlyPayment.installments?.amount ?? 0
  );

  const selectedTier = getCurrentTier(quote)?.name;

  const periodText = isAnnualPayment(quote)
    ? csTieringTable.chevron.annual_label
    : csTieringTable.chevron.monthly_label;

  const essentialsBreakdownLabels = replaceBreakdownPlaceholders(
    csTieringTable.content_rows.breakdown,
    quote,
    0
  );
  const standardBreakdownLabels = replaceBreakdownPlaceholders(
    csTieringTable.content_rows.breakdown,
    quote,
    1
  );

  const isBreakdownSelected =
    quote.breakdown.selectedBreakdown.isSelected ??
    quote.breakdown.selectedBreakdown.isSelectedOnAggregator;

  const essentialsLegalLabels = replaceLegalPlaceholders(
    csTieringTable.content_rows.legal,
    quote,
    0
  );

  const standardLegalLabels = replaceLegalPlaceholders(
    csTieringTable.content_rows.legal,
    quote,
    1
  );

  const legalAddOnData = quote.coverages.find((cov) => cov.type === LegalCoverageType);

  const isLegalSelected =
    legalAddOnData?.isSelected ?? legalAddOnData?.isSelectedOnAggregator ?? false;

  const tierNameRowStyle = {
    borderBottomWidth: 0,
    marginBottom: -8,
  };

  return (
    <Section>
      <Table theme={theme}>
        <TableRow>
          <TableCell theme={theme} width={firstColumnWidth} style={tierNameRowStyle} />
          <TableCell theme={theme} width={otherColumnWidth} style={tierNameRowStyle}>
            {csTieredCheckDetails.your_cover.cover_labels.essentials}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth} style={tierNameRowStyle}>
            {csTieredCheckDetails.your_cover.cover_labels.standard}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth} style={tierNameRowStyle}>
            {csTieredCheckDetails.your_cover.cover_labels.extra}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell theme={theme} width={firstColumnWidth} />
          <TableCell theme={theme} width={otherColumnWidth}>
            {selectedTier === TierOptions.Essentials
              ? csTieringTable.chevron.selected_button_label
              : ''}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth}>
            {selectedTier === TierOptions.Standard
              ? csTieringTable.chevron.selected_button_label
              : ''}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth}>
            {selectedTier === TierOptions.Extra
              ? csTieringTable.chevron.selected_button_label
              : ''}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell theme={theme} width={firstColumnWidth}>
            Price
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth}>
            {`${formatInPoundsAndPenceWithSign(tierPrices?.[0])} ${periodText}`}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth}>
            {`${formatInPoundsAndPenceWithSign(tierPrices?.[1])} ${periodText}`}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth}>
            {`${formatInPoundsAndPenceWithSign(tierPrices?.[2])} ${periodText}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell theme={theme} width={firstColumnWidth}>
            {csTieringTable.content_rows.excess.excess_row_header}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth}>
            {formatInPoundsWithSign(quote.tieringInfo?.tiers[0].compulsoryExcess)}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth}>
            {formatInPoundsWithSign(quote.tieringInfo?.tiers[1].compulsoryExcess)}
          </TableCell>
          <TableCell theme={theme} width={otherColumnWidth}>
            {formatInPoundsWithSign(quote.tieringInfo?.tiers[2].compulsoryExcess)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell theme={theme} width={firstColumnWidth}>
            {csTieringTable.content_rows.breakdown.breakdown_row_header}
          </TableCell>
          <ProductStatusCell
            theme={theme}
            status={
              isBreakdownSelected
                ? ProductItemStatus.SELECTED
                : ProductItemStatus.UNSELECTED
            }
            text={
              isBreakdownSelected
                ? essentialsBreakdownLabels.selected_text
                : essentialsBreakdownLabels.unselected_text
            }
          />
          <ProductStatusCell
            theme={theme}
            status={
              isBreakdownSelected
                ? ProductItemStatus.SELECTED
                : ProductItemStatus.UNSELECTED
            }
            text={
              isBreakdownSelected
                ? standardBreakdownLabels.selected_text
                : standardBreakdownLabels.unselected_text
            }
          />
          <ProductStatusCell
            theme={theme}
            status={ProductItemStatus.INCLUSIVE}
            text={csTieringTable.status_icons.inclusive_icon_screenreader_text}
          />
        </TableRow>
        <TableRow>
          <TableCell theme={theme} width={firstColumnWidth}>
            {csTieringTable.content_rows.legal.legal_row_header}
          </TableCell>
          <ProductStatusCell
            theme={theme}
            status={
              isLegalSelected ? ProductItemStatus.SELECTED : ProductItemStatus.UNSELECTED
            }
            text={
              isLegalSelected
                ? essentialsLegalLabels.selected_text
                : essentialsLegalLabels.unselected_text
            }
          />
          <ProductStatusCell
            theme={theme}
            status={
              isLegalSelected ? ProductItemStatus.SELECTED : ProductItemStatus.UNSELECTED
            }
            text={
              isLegalSelected
                ? standardLegalLabels.selected_text
                : standardLegalLabels.unselected_text
            }
          />
          <ProductStatusCell
            theme={theme}
            status={ProductItemStatus.INCLUSIVE}
            text={csTieringTable.status_icons.inclusive_icon_screenreader_text}
          />
        </TableRow>
        {csTieringTable.content_rows.additional_covers.map((cover) => (
          <TableRow key={cover.cover_name}>
            <TableCell theme={theme} width={firstColumnWidth}>
              {cover.cover_name}
            </TableCell>
            <ProductStatusCell
              theme={theme}
              status={
                cover.included_with_essentials
                  ? ProductItemStatus.INCLUSIVE
                  : ProductItemStatus.UNAVAILABLE
              }
              text={
                cover.included_with_essentials
                  ? csTieringTable.status_icons.inclusive_icon_screenreader_text
                  : csTieringTable.status_icons.unavailable_icon_screenreader_text
              }
            />
            <ProductStatusCell
              theme={theme}
              status={
                cover.included_with_standard
                  ? ProductItemStatus.INCLUSIVE
                  : ProductItemStatus.UNAVAILABLE
              }
              text={
                cover.included_with_standard
                  ? csTieringTable.status_icons.inclusive_icon_screenreader_text
                  : csTieringTable.status_icons.unavailable_icon_screenreader_text
              }
            />
            <ProductStatusCell
              theme={theme}
              status={
                cover.included_with_extra
                  ? ProductItemStatus.INCLUSIVE
                  : ProductItemStatus.UNAVAILABLE
              }
              text={
                cover.included_with_extra
                  ? csTieringTable.status_icons.inclusive_icon_screenreader_text
                  : csTieringTable.status_icons.unavailable_icon_screenreader_text
              }
            />
          </TableRow>
        ))}
      </Table>
    </Section>
  );
};

export default TieringTable;
