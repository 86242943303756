import { View } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { getCompulsoryExcess, getQuoteTotalExcess } from 'api/businessLogic/excess';
import { CsQuoteSummaryPdf } from 'pdf/TieredQuoteSummaryPdf/types';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { Table, TableCell, TableRow } from 'pdf/components/Table';
import { Heading3 } from 'pdf/components/typography';
import React from 'react';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { Quote, Renewal } from 'state/quote/quote';

const tableColumnWidth = '50%';

type ExcessTableRowProps = {
  theme: Theme;
  heading: string;
  value: string;
};

const ExcessTableRow: React.FC<ExcessTableRowProps> = ({ theme, heading, value }) => (
  <TableRow>
    <TableCell theme={theme} width={tableColumnWidth}>
      {heading}
    </TableCell>
    <TableCell theme={theme} width={tableColumnWidth}>
      {value}
    </TableCell>
  </TableRow>
);

type ExcessSectionProps = {
  theme: Theme;
  quote: Quote | Renewal;
  csQuoteSummary: CsQuoteSummaryPdf;
};

const ExcessSection: React.FC<ExcessSectionProps> = ({
  theme,
  quote,
  csQuoteSummary,
}) => {
  const { heading, body, labels } = csQuoteSummary.excess_section;

  const { voluntaryExcess } = quote.excess;
  const totalExcess = getQuoteTotalExcess(quote);

  const policyHolderCompulsoryExcess = getCompulsoryExcess(totalExcess, voluntaryExcess);

  return (
    <Section>
      <View wrap={false}>
        <Heading3>{heading}</Heading3>
        <Table theme={theme} style={{ marginBottom: 8 }}>
          <ExcessTableRow
            theme={theme}
            heading={labels.compulsory_label}
            value={formatInPoundsWithSign(policyHolderCompulsoryExcess)}
          />
          <ExcessTableRow
            theme={theme}
            heading={labels.voluntary_label}
            value={formatInPoundsWithSign(voluntaryExcess)}
          />
          <ExcessTableRow
            theme={theme}
            heading={labels.total_label}
            value={formatInPoundsWithSign(totalExcess)}
          />
        </Table>
        <RichText theme={theme} html={body} />
      </View>
    </Section>
  );
};

export default ExcessSection;
