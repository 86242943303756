import { View } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { isAnnualPayment } from 'api/businessLogic/quote';
import {
  CsAnnualPaymentTablePdf,
  CsMonthlyPaymentTablePdf,
  CsQuoteSummaryPdf,
} from 'pdf/TieredQuoteSummaryPdf/types';
import CustomTable from 'pdf/components/CustomTable';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { TableCell } from 'pdf/components/Table';
import { Heading3 } from 'pdf/components/typography';
import React from 'react';
import { isRenewal, Quote, Renewal } from 'state/quote/quote';

type PaymentSectionProps = {
  theme: Theme;
  quote: Quote | Renewal;
  csQuoteSummary: CsQuoteSummaryPdf;
  csMonthlyPaymentTable: CsMonthlyPaymentTablePdf;
  csAnnualPaymentTable: CsAnnualPaymentTablePdf;
};

const PaymentSection: React.FC<PaymentSectionProps> = ({
  theme,
  quote,
  csQuoteSummary,
  csMonthlyPaymentTable,
  csAnnualPaymentTable,
}) => {
  const monthlyTable = isRenewal(quote)
    ? csMonthlyPaymentTable.table_renewal
    : csMonthlyPaymentTable.table;

  const table = isAnnualPayment(quote) ? csAnnualPaymentTable.table : monthlyTable;

  return (
    <Section>
      <View wrap={false}>
        <Heading3>{csQuoteSummary.payment_section.heading}</Heading3>
        <CustomTable theme={theme} table={table}>
          {!isAnnualPayment(quote) && (
            <TableCell
              theme={theme}
              width="100%"
              style={{
                paddingHorizontal: 8,
              }}>
              <RichText
                theme={theme}
                html={csQuoteSummary.payment_section.monthly_payment_details}
              />
            </TableCell>
          )}
        </CustomTable>
      </View>
    </Section>
  );
};

export default PaymentSection;
