import { Text, View } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { isAnnualPayment } from 'api/businessLogic/quote';
import {
  CsPaymentPdf,
  CsQuoteSummaryPdf,
  CsTieredCheckDetailsPdf,
  CsTieredQuoteSummaryPdf,
} from 'pdf/TieredQuoteSummaryPdf/types';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { Heading3 } from 'pdf/components/typography';
import React from 'react';
import { formatInPoundsAndPenceWithSign } from 'helpers/poundsHelpers';
import { getCurrentTier, Quote, Renewal, TierOptions } from 'state/quote/quote';

type SelectedTierSectionProps = {
  theme: Theme;
  quote: Quote | Renewal;
  csQuoteSummary: CsQuoteSummaryPdf;
  csTieredQuoteSummary: CsTieredQuoteSummaryPdf;
  csTieredCheckDetails: CsTieredCheckDetailsPdf;
  csPayment: CsPaymentPdf;
};

const textForTier = (
  tier?: TierOptions,
  content?: CsTieredCheckDetailsPdf
): string | undefined => {
  switch (tier) {
    case TierOptions.Essentials:
      return content?.your_cover.cover_labels.essentials;
    case TierOptions.Standard:
      return content?.your_cover.cover_labels.standard;
    case TierOptions.Extra:
      return content?.your_cover.cover_labels.extra;
    default:
      return undefined;
  }
};

type PaymentDependentData = {
  priceHeader: string;
  paymentText: string;
  summaryDetails: string;
};

const getPaymentDependentDetails = (
  quote: Quote,
  csQuoteSummary: CsQuoteSummaryPdf
): PaymentDependentData => {
  const { quote_details } = csQuoteSummary.summary_section;
  if (isAnnualPayment(quote)) {
    return {
      priceHeader: formatInPoundsAndPenceWithSign(
        quote.paymentDetails.annualPayment.total
      ),
      paymentText: quote_details.annual_payment_text,
      summaryDetails: quote_details.annual_details,
    };
  }
  return {
    priceHeader: formatInPoundsAndPenceWithSign(
      quote.paymentDetails.monthlyPayment.installments?.amount
    ),
    paymentText: quote_details.monthly_payment_text,
    summaryDetails: quote_details.monthly_details,
  };
};

const SelectedTierSection: React.FC<SelectedTierSectionProps> = ({
  theme,
  quote,
  csQuoteSummary,
  csTieredQuoteSummary,
  csTieredCheckDetails,
  csPayment,
}) => {
  const currentTier = getCurrentTier(quote);

  const { quote_details } = csQuoteSummary.summary_section;
  const { paymentText, priceHeader, summaryDetails } = getPaymentDependentDetails(
    quote,
    csQuoteSummary
  );

  return (
    <Section>
      <Heading3>{`${csTieredQuoteSummary.selected_tier_card.welcome_text} ${textForTier(
        currentTier?.name,
        csTieredCheckDetails
      )}`}</Heading3>
      <Text style={{ marginBottom: 4 }}>{`${priceHeader} ${paymentText}`}</Text>
      <RichText theme={theme} html={summaryDetails} />
      <View
        style={{
          marginBottom: '8',
        }}>
        {quote.paymentDetails.annualPaymentOnly ? (
          <Text>
            {csQuoteSummary.summary_section.quote_details.unable_to_pay_monthly_text}
          </Text>
        ) : (
          <Text>
            {isAnnualPayment(quote)
              ? csPayment.payment_summary.switch_payment_buttons.switch_to_monthly
              : csPayment.payment_summary.switch_payment_buttons.switch_to_annual}
          </Text>
        )}
      </View>
      <RichText theme={theme} html={quote_details.start_date_label} />
      <RichText theme={theme} html={quote_details.quote_reference_label} />
      <RichText
        theme={theme}
        html={csTieredQuoteSummary.summary_section.additional_info_text}
      />
    </Section>
  );
};

export default SelectedTierSection;
