import { Theme } from '@rsa-digital/evo-shared-components/theme';
import {
  CsAddOnBreakdownCoverPdf,
  CsAddOnIncludedInExtraPdf,
  CsAddOnPdf,
  CsAddOnsConfigPdf,
  CsQuoteSummaryPdf,
} from 'pdf/TieredQuoteSummaryPdf/types';
import Section from 'pdf/components/Section';
import { Heading2 } from 'pdf/components/typography';
import React from 'react';
import { sortCoveragesWithLegalFirst } from 'components/QuoteSummary/TieredQuoteSummaryPage';
import getTieredData from 'helpers/getTieredData';
import { AddonSelection } from 'state/addonsSelection/addonsSelection';
import { AddOn, Quote, Renewal, TierInfo } from 'state/quote/quote';
import AddOnInfo from '../AddOnInfo';
import BreakdownInfo from '../BreakdownInfo';

const isCoverageSelectedLocally = (
  addOnSelection: AddonSelection,
  type: string
): boolean | null => {
  const localCoverage = addOnSelection.localCoverages?.find(
    (addOn) => addOn.type === type
  );

  return localCoverage?.isSelected ?? null;
};

const getCoverages = (
  selectedTierInfo: TierInfo | undefined,
  quote: Quote | Renewal,
  addOnSelection: AddonSelection
): AddOn[] => {
  if (!selectedTierInfo) {
    return quote.coverages;
  }

  return selectedTierInfo.coverages.map((tierAddOn) => ({
    ...tierAddOn,
    isSelected: isCoverageSelectedLocally(addOnSelection, tierAddOn.type),
  }));
};

type CoversSectionProps = {
  theme: Theme;
  quote: Quote | Renewal;
  addOnSelection: AddonSelection;
  csQuoteSummary: CsQuoteSummaryPdf;
  csAddOns: CsAddOnPdf[];
  csAddOnsConfig: CsAddOnsConfigPdf;
  csAddOnBreakdownCover: CsAddOnBreakdownCoverPdf;
  csAddOnIncludedInExtra: CsAddOnIncludedInExtraPdf;
};

const CoversSection: React.FC<CoversSectionProps> = ({
  theme,
  quote,
  addOnSelection,
  csQuoteSummary,
  csAddOns,
  csAddOnsConfig,
  csAddOnBreakdownCover,
  csAddOnIncludedInExtra,
}) => {
  const selectedTier = quote.tieringInfo?.selectedTier;
  const selectedTierInfo =
    selectedTier && quote.tieringInfo
      ? getTieredData(selectedTier, quote.tieringInfo.tiers)
      : undefined;
  const coverages = getCoverages(selectedTierInfo, quote, addOnSelection);
  const orderedCoverages = sortCoveragesWithLegalFirst(coverages);

  return (
    <Section>
      <Heading2>{csQuoteSummary.heading}</Heading2>
      <BreakdownInfo
        theme={theme}
        quote={quote}
        addOnSelection={addOnSelection}
        csAddOnBreakdownCover={csAddOnBreakdownCover}
        csAddOnIncludedInExtra={csAddOnIncludedInExtra}
        csAddOnsConfig={csAddOnsConfig}
      />
      {orderedCoverages.map((addOnData) => {
        const addOnContent = csAddOns.find((a) => a.type === addOnData.type);
        if (!addOnContent) {
          return null;
        }
        return (
          <AddOnInfo
            key={addOnData.type}
            theme={theme}
            quote={quote}
            addOn={addOnData}
            addOnContent={addOnContent}
            csAddOnsConfig={csAddOnsConfig}
            csAddOnIncludedInExtra={csAddOnIncludedInExtra}
          />
        );
      })}
    </Section>
  );
};

export default CoversSection;
