import { Text, View } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { isAnnualPayment } from 'api/businessLogic/quote';
import {
  CsAddOnBreakdownCoverPdf,
  CsAddOnIncludedInExtraPdf,
  CsAddOnsConfigPdf,
} from 'pdf/TieredQuoteSummaryPdf/types';
import BulletItem from 'pdf/components/BulletItem';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { EmphasisParagraphText, Heading3 } from 'pdf/components/typography';
import React from 'react';
import { getFromSubheading } from 'components/QuoteSummary/BreakdownAddOnChooser/headingHelpers';
import {
  getBreakdownPrice,
  getBreakdownPriceForExtraTier,
} from 'helpers/breakdownPriceHelper';
import getTieredData from 'helpers/getTieredData';
import { formatInPoundsAndPenceWithSign } from 'helpers/poundsHelpers';
import replaceAddOnCostPlaceholders from 'helpers/replaceAddonCostPlaceholder';
import { AddonSelection } from 'state/addonsSelection/addonsSelection';
import { BreakdownCoverLevel, Quote, Renewal, TierOptions } from 'state/quote/quote';
import { getAddOnPanelStateInfo } from '../AddOnInfo';

const includesText = 'Includes:';

type BreakdownSummaryProps = {
  theme: Theme;
  heading: string;
  summary: string;
  price: string;
  selectedText: string | null;
  includedItems: string[];
};

const BreakdownSummary: React.FC<BreakdownSummaryProps> = ({
  theme,
  heading,
  summary,
  price,
  selectedText,
  includedItems,
}) => (
  <>
    <EmphasisParagraphText
      style={{
        marginTop: '8',
        marginBottom: '4',
      }}>
      {heading}
    </EmphasisParagraphText>
    <EmphasisParagraphText
      style={{
        color: theme.colors.core01,
      }}>
      {price}
    </EmphasisParagraphText>
    {selectedText && <RichText theme={theme} html={selectedText} />}
    <Text>{summary}</Text>
    <Text style={{ marginTop: 2 }}>{includesText}</Text>
    {includedItems.map((item) => (
      <BulletItem key={item} theme={theme}>
        {item}
      </BulletItem>
    ))}
  </>
);

type BreakdownInfoProps = {
  theme: Theme;
  quote: Quote | Renewal;
  addOnSelection: AddonSelection;
  csAddOnBreakdownCover: CsAddOnBreakdownCoverPdf;
  csAddOnIncludedInExtra: CsAddOnIncludedInExtraPdf;
  csAddOnsConfig: CsAddOnsConfigPdf;
};

const BreakdownInfo: React.FC<BreakdownInfoProps> = ({
  theme,
  quote,
  csAddOnBreakdownCover,
  csAddOnIncludedInExtra,
  csAddOnsConfig,
}) => {
  const selectedTier = quote.tieringInfo?.selectedTier;
  const selectedTierInfo =
    selectedTier && quote.tieringInfo
      ? getTieredData(selectedTier, quote.tieringInfo.tiers)
      : undefined;

  const breakdownCovers = selectedTierInfo?.breakdownCovers ?? quote.breakdown.covers;

  const selectedBreakdown = quote.breakdown.selectedBreakdown.coverLevel;

  const isExtraTierSelected = quote.tieringInfo?.selectedTier === TierOptions.Extra;
  const isAnnual = isAnnualPayment(quote);

  const calculateBreakdownPrice = isExtraTierSelected
    ? getBreakdownPriceForExtraTier
    : getBreakdownPrice;

  const getPriceTextForBreakdownLevel = (coverType: BreakdownCoverLevel): string => {
    const breakdownPrice = calculateBreakdownPrice(coverType, isAnnual, breakdownCovers);

    const additionalPriceOnExtraText = isAnnual
      ? replaceAddOnCostPlaceholders(
          csAddOnIncludedInExtra.additional_price_on_extra_annual_subheading,
          breakdownPrice
        )
      : replaceAddOnCostPlaceholders(
          csAddOnIncludedInExtra.additional_price_on_extra_monthly_subheading,
          breakdownPrice
        );

    const extraTierText =
      isExtraTierSelected && breakdownPrice === 0
        ? csAddOnIncludedInExtra.included_text
        : additionalPriceOnExtraText;

    const nonExtraTierText = `${formatInPoundsAndPenceWithSign(breakdownPrice)} ${
      isAnnual
        ? csAddOnBreakdownCover.cover_options.price_suffix.annual
        : csAddOnBreakdownCover.cover_options.price_suffix.monthly
    }`;

    return isExtraTierSelected ? extraTierText : nonExtraTierText;
  };

  const breakdownPriceText = selectedBreakdown
    ? getPriceTextForBreakdownLevel(selectedBreakdown)
    : getFromSubheading(breakdownCovers, csAddOnBreakdownCover, isAnnual);

  const panelStateInfo = getAddOnPanelStateInfo(
    csAddOnsConfig,
    quote.breakdown.selectedBreakdown.isSelected,
    quote.breakdown.selectedBreakdown.isSelectedOnAggregator,
    isExtraTierSelected ? csAddOnIncludedInExtra.panel_state : undefined
  );

  const summaryText =
    quote.breakdown.selectedBreakdown.isSelectedOnAggregator &&
    quote.breakdown.selectedBreakdown.isSelected === null
      ? csAddOnBreakdownCover.aggregator_pre_selection_summary
      : csAddOnBreakdownCover.pre_selection_summary;

  return (
    <Section>
      <Heading3>{csAddOnBreakdownCover.heading}</Heading3>
      {panelStateInfo?.corner_label.text && (
        <RichText theme={theme} html={panelStateInfo.corner_label.text} />
      )}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: 0,
        }}>
        <Heading3 style={{ color: theme.colors.core01, marginTop: 0 }}>
          {!selectedBreakdown && isExtraTierSelected
            ? csAddOnIncludedInExtra.included_text
            : breakdownPriceText}
        </Heading3>
        {selectedBreakdown && !isExtraTierSelected && (
          <Heading3
            style={{
              fontWeight: 'light',
              marginTop: 0,
              marginLeft: '8',
              color: theme.colors.core01,
            }}>
            {csAddOnsConfig.cover_added_state.included_text}
          </Heading3>
        )}
      </View>
      <RichText theme={theme} html={summaryText} />

      <BreakdownSummary
        theme={theme}
        heading={csAddOnBreakdownCover.cover_options.roadside.selection.heading}
        summary={csAddOnBreakdownCover.cover_options.roadside.selection.subheading}
        price={getPriceTextForBreakdownLevel(BreakdownCoverLevel.Roadside)}
        selectedText={
          selectedBreakdown === BreakdownCoverLevel.Roadside
            ? csAddOnsConfig.cover_added_state.cover_added_default_state.corner_label.text
            : null
        }
        includedItems={[csAddOnBreakdownCover.cover_options.whats_included.roadside]}
      />

      <BreakdownSummary
        theme={theme}
        heading={csAddOnBreakdownCover.cover_options.home.selection.heading}
        summary={csAddOnBreakdownCover.cover_options.home.selection.subheading}
        price={getPriceTextForBreakdownLevel(BreakdownCoverLevel.Homecall)}
        selectedText={
          selectedBreakdown === BreakdownCoverLevel.Homecall
            ? csAddOnsConfig.cover_added_state.cover_added_default_state.corner_label.text
            : null
        }
        includedItems={[
          csAddOnBreakdownCover.cover_options.whats_included.roadside,
          csAddOnBreakdownCover.cover_options.whats_included.home,
        ]}
      />

      <BreakdownSummary
        theme={theme}
        heading={csAddOnBreakdownCover.cover_options.recovery.selection.heading}
        summary={csAddOnBreakdownCover.cover_options.recovery.selection.subheading}
        price={getPriceTextForBreakdownLevel(BreakdownCoverLevel.Recovery)}
        selectedText={
          selectedBreakdown === BreakdownCoverLevel.Recovery
            ? csAddOnsConfig.cover_added_state.cover_added_default_state.corner_label.text
            : null
        }
        includedItems={[
          csAddOnBreakdownCover.cover_options.whats_included.roadside,
          csAddOnBreakdownCover.cover_options.whats_included.home,
          csAddOnBreakdownCover.cover_options.whats_included.recovery,
        ]}
      />

      <BreakdownSummary
        theme={theme}
        heading={csAddOnBreakdownCover.cover_options.europe.selection.heading}
        summary={csAddOnBreakdownCover.cover_options.europe.selection.subheading}
        price={getPriceTextForBreakdownLevel(BreakdownCoverLevel.European)}
        selectedText={
          selectedBreakdown === BreakdownCoverLevel.European
            ? csAddOnsConfig.cover_added_state.cover_added_default_state.corner_label.text
            : null
        }
        includedItems={[
          csAddOnBreakdownCover.cover_options.whats_included.roadside,
          csAddOnBreakdownCover.cover_options.whats_included.home,
          csAddOnBreakdownCover.cover_options.whats_included.recovery,
          csAddOnBreakdownCover.cover_options.whats_included.europe,
        ]}
      />
    </Section>
  );
};

export default BreakdownInfo;
