import { View } from '@react-pdf/renderer';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import { isAnnualPayment } from 'api/businessLogic/quote';
import {
  CsAddOnIncludedInExtraPdf,
  CsAddOnPdf,
  CsAddOnsConfigPdf,
  CsPanelStatePdf,
} from 'pdf/TieredQuoteSummaryPdf/types';
import RichText from 'pdf/components/RichText';
import Section from 'pdf/components/Section';
import { Heading3 } from 'pdf/components/typography';
import React from 'react';
import replaceAddOnCostPlaceholders from 'helpers/replaceAddonCostPlaceholder';
import {
  AddOn,
  getCurrentTier,
  LegalCoverageType,
  Quote,
  Renewal,
  TierOptions,
} from 'state/quote/quote';

export const getAddOnPanelStateInfo = (
  csAddOnsConfig: CsAddOnsConfigPdf,
  isSelected: boolean | null,
  isSelectedOnAggregator: boolean,
  manuallySetExtraState?: CsPanelStatePdf
): CsPanelStatePdf | null => {
  if (isSelected === false) {
    return csAddOnsConfig.cover_declined_state;
  }

  if (isSelected) {
    if (manuallySetExtraState) {
      return manuallySetExtraState;
    }

    if (isSelectedOnAggregator) {
      return csAddOnsConfig.cover_added_state.cover_added_aggregator_state;
    }

    return csAddOnsConfig.cover_added_state.cover_added_default_state;
  }

  if (manuallySetExtraState) {
    return manuallySetExtraState;
  }

  if (isSelectedOnAggregator) {
    return csAddOnsConfig.cover_selected_aggregator_state;
  }

  return null;
};

type AddOnInfoProps = {
  theme: Theme;
  quote: Quote | Renewal;
  addOn: AddOn;
  addOnContent: CsAddOnPdf;
  csAddOnsConfig: CsAddOnsConfigPdf;
  csAddOnIncludedInExtra: CsAddOnIncludedInExtraPdf;
};

const AddOnInfo: React.FC<AddOnInfoProps> = ({
  theme,
  quote,
  addOn,
  addOnContent,
  csAddOnsConfig,
  csAddOnIncludedInExtra,
}) => {
  const subheading = isAnnualPayment(quote)
    ? replaceAddOnCostPlaceholders(csAddOnsConfig.yearly_subheading, addOn.annualPremium)
    : replaceAddOnCostPlaceholders(
        csAddOnsConfig.monthly_subheading,
        addOn.monthlyInstallmentPremium
      );

  const selectedTier = getCurrentTier(quote)?.name;
  const isExtraTier = selectedTier === TierOptions.Extra;

  const [addOnIncludedState, includedSubheading] =
    addOn.type === LegalCoverageType && isExtraTier
      ? [csAddOnIncludedInExtra.panel_state, csAddOnIncludedInExtra.included_text]
      : [undefined, null];

  const addOnState = getAddOnPanelStateInfo(
    csAddOnsConfig,
    addOn.isSelected,
    addOn.isSelectedOnAggregator,
    addOnIncludedState
  );

  return (
    <Section>
      <Heading3>{addOnContent.heading}</Heading3>
      {addOnState?.corner_label.text && (
        <RichText theme={theme} html={addOnState.corner_label.text} />
      )}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: 0,
        }}>
        <Heading3 style={{ color: theme.colors.core01, marginTop: 0 }}>
          {includedSubheading ?? subheading}
        </Heading3>
        {addOn.isSelected && !addOnIncludedState && (
          <Heading3
            style={{
              fontWeight: 'light',
              marginTop: 0,
              marginLeft: '8',
              color: theme.colors.core01,
            }}>
            {csAddOnsConfig.cover_added_state.included_text}
          </Heading3>
        )}
      </View>
      <RichText theme={theme} html={addOnContent.pre_selection_summary} />
      <RichText theme={theme} html={addOnContent.details} />
    </Section>
  );
};

export default AddOnInfo;
