import { Theme } from '@rsa-digital/evo-shared-components/theme';
import React from 'react';
import { CsTable } from 'types/contentStack';
import RichText from '../RichText';
import { Table, TableCell, TableRow } from '../Table';

type CustomTableProps = {
  theme: Theme;
  table: CsTable;
};

const CustomTable: React.FC<CustomTableProps> = ({ theme, table, children }) => {
  const totalColumnSpan =
    table.content_rows[table.content_rows.length - 1]?.content_row.cells.reduce(
      (totalColSpan, cell) => totalColSpan + cell.cell.column_span,
      0
    ) ?? 1;

  const getCellWidth = (columnSpan: number): string =>
    `${(columnSpan * 100) / totalColumnSpan}%`;

  return (
    <Table theme={theme}>
      <TableRow>
        {table.header_row.has_empty_corner_cell && (
          <TableCell
            theme={theme}
            width={getCellWidth(table.header_row.corner_cell_column_span ?? 1)}
          />
        )}
        {table.header_row.header_cells.map((cell) => (
          <TableCell theme={theme} width={getCellWidth(cell.column_span)}>
            {cell.heading_text}
          </TableCell>
        ))}
      </TableRow>
      {table.content_rows.map((row, rowIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={rowIndex}>
          {row.content_row.cells.map((cell, columnIndex) => (
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={columnIndex}
              theme={theme}
              width={getCellWidth(cell.cell.column_span)}>
              <RichText theme={theme} html={cell.cell.content_rich_text} />
            </TableCell>
          ))}
        </TableRow>
      ))}
      {children}
    </Table>
  );
};

export default CustomTable;
